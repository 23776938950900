.home-container {
  padding: 2rem 3rem;
  margin-left: 250px;
  width: calc(100vw - 250px);
}

.welcome-message {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
}

.stats-section {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(100% - 2rem);
  max-width: 100%;
}

.stats-section h2,
.user-data h2 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.stat-box {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
}

.stat-box h4 {
  color: #6c757d;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.stat-box p {
  color: #212529;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.user-data {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(100% - 2rem);
  max-width: 100%;
}

.search-container {
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  font-size: 0.875rem;
}

.search-input:focus {
  outline: none;
  border-color: #4285f4;
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.1);
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.data-table thead {
  background-color: #f8f9fa;
}

.data-table th {
  padding: 1rem;
  text-align: left;
  font-weight: 600;
  color: #495057;
  border-bottom: 2px solid #dee2e6;
}

.data-table td {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  color: #495057;
}

.data-table tbody tr {
  background-color: #fff;
  transition: background-color 0.2s ease;
}

.data-table tbody tr:hover {
  background-color: rgba(10, 30, 60, 0.05);
  cursor: pointer;
}

.data-table tbody tr:last-child td {
  border-bottom: none;
}

.data-table tbody tr.custom-property,
.data-table tbody tr.global-property {
  background-color: #fff;
  transition: background-color 0.2s ease;
}

.data-table tbody tr.custom-property:hover,
.data-table tbody tr.global-property:hover {
  background-color: rgba(10, 30, 60, 0.05);
  cursor: pointer;
}

.view-button {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s ease;
}

.view-button:hover {
  background-color: #3367d6;
}

.loading-spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.pagination-button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pagination-button:hover {
  background-color: #0056b3;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  color: #666;
  font-size: 0.9rem;
}

.page-info {
  font-size: 0.9rem;
  color: #666;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modal-content {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 800px;
  max-height: 85vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
}

.modal-header h4 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.close-button:hover {
  background-color: #f5f5f5;
  color: #333;
}

.modal-body {
  padding: 20px;
}

.modal-body p {
  margin: 10px 0;
  line-height: 1.5;
}

.modal-body h5 {
  margin: 20px 0 10px;
  color: #333;
}

.modal-body .data-table {
  margin-top: 10px;
  border: 1px solid #eee;
  border-radius: 8px;
}

.property-name {
  font-weight: 500;
  color: #2563eb;
}

.property-type {
  text-transform: capitalize;
  color: #666;
}

.property-count {
  font-variant-numeric: tabular-nums;
  text-align: right;
}

.property-example {
  color: #666;
  font-family: monospace;
  font-size: 13px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.refresh-section {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 15rem;
  padding: 0 2rem;
}

.refresh-button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.refresh-button:hover {
  background-color: #0056b3;
}

.refresh-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.last-refresh {
  color: #666;
  font-size: 0.9rem;
}

.error-message {
  color: #dc3545;
  padding: 1rem;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.stats-section {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stats-section h2 {
  margin-bottom: 20px;
  color: #333;
}

.welcome-message h1 {
  margin: 0;
  display: inline;
  white-space: nowrap;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.pagination-button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pagination-button:hover {
  background-color: #0056b3;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  color: #666;
  font-size: 0.9rem;
}

.page-info {
  font-size: 0.9rem;
  color: #666;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
  
  .admin-stats {
    position: static;
  }
  
  .stats-grid {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .stats-grid {
    flex-wrap: wrap;
  }
  
  .stat-card {
    flex: 1 1 calc(50% - 0.5rem);
    min-width: calc(50% - 0.5rem);
  }
}

@media (max-width: 576px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }
  
  .stat-card {
    flex: 1 1 100%;
  }
}
