.settings-container {
  padding: 2rem;
}

.settings-title {
  margin-bottom: 2rem;
  color: #333;
}

.coming-soon {
  font-size: 1.5rem;
  font-weight: bold;
  color: #666;
  margin-top: 4rem;
}
