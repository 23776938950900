.property-breakdown {
  padding: 2rem 2rem 2rem 4rem;
  max-width: 1400px;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.back-button {
  padding: 0.5rem 1rem;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.back-button:hover {
  background-color: #e5e5e5;
}

.property-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #333;
}

.content-grid {
  /*display: grid;*/
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
}

.values-section {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.values-section h3 {
  margin: 0 0 1.5rem 0;
  color: #333;
}

.values-table table {
  width: 100%;
  border-collapse: collapse;
}

.values-table th,
.values-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e9ecef;
}

.values-table th {
  font-weight: 600;
  color: #666;
}

.color-key {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.chart-section {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.chart-container {
  /*height: 300px;*/
  position: relative;
}

.profiles-section {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  grid-column: 1 / -1;
}

.profiles-section h3 {
  margin: 0 0 1.5rem 0;
  color: #333;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.search-input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: border-color 0.2s;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
}

.search-results-count {
  color: #6c757d;
  font-size: 0.9rem;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e9ecef;
}

.data-table th {
  font-weight: 600;
  color: #666;
}

.data-table tbody tr:hover {
  background-color: #f8f9fa;
}

.instructions-section {
  margin-top: 2rem;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.instructions-section h3 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.instructions-section ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.instructions-section ol > li {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.instructions-section ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
}

.instructions-section ul > li {
  margin-bottom: 0.5rem;
  color: #555;
}

.instructions-section .path {
  background: #f5f5f5;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-family: monospace;
}

.instructions-section strong {
  color: #1976D2;
  font-weight: 500;
}

.instructions-section .note {
  margin-top: 1.5rem;
  padding: 1rem;
  background: #f8f9fa;
  border-left: 4px solid #1976D2;
  border-radius: 4px;
}

.instructions-section .note strong {
  color: #333;
}
