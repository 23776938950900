.content {
  padding: 1.5rem;
  width: calc(100vw - 150px - 3rem); /* viewport width minus sidebar and padding */
  margin-left: 150px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.card {
  max-width: 2000px !important; /* Increased from 1600px */
  width: 100%;
  background: #fff;
  border: 1px solid rgba(98, 105, 118, 0.16);
  border-radius: 4px;
  box-shadow: rgba(35, 46, 60, 0.04) 0 2px 4px 0;
  margin-bottom: 1.5rem;
}

.card-title {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 500;
  margin: 0 0 1rem 0;
  padding: 1.5rem 1.5rem 0;
}

.search-container {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(98, 105, 118, 0.16);
}

.search-bar-wrapper {
  position: relative;
  max-width: 24rem;
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #626976;
}

.search-input {
  padding: 0.5rem 0.75rem 0.5rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.4285714;
  border: 1px solid rgba(98, 105, 118, 0.16);
  border-radius: 4px;
  width: 100%;
  transition: border-color 0.15s ease-in-out;
}

.search-input:focus {
  border-color: #206bc4;
  outline: none;
  box-shadow: 0 0 0 3px rgba(32, 107, 196, 0.1);
}

.table-container {
  margin: 0;
  padding: 0 1.5rem 1.5rem;
  width: 100%;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  font-size: 0.875rem;
  table-layout: fixed;
}

.users-table th {
  padding: 0.75rem;
  font-weight: 600;
  text-align: left;
  border-bottom: 2px solid rgba(98, 105, 118, 0.16);
  color: #626976;
  white-space: nowrap;
}

.users-table th:nth-child(1),
.users-table td:nth-child(1) { width: 18%; } /* Company Name */

.users-table th:nth-child(2),
.users-table td:nth-child(2) { width: 15%; } /* User Name */

.users-table th:nth-child(3),
.users-table td:nth-child(3) { width: 18%; } /* Email */

.users-table th:nth-child(4),
.users-table td:nth-child(4) { width: 23%; } /* Klaviyo API Key */

.users-table th:nth-child(5),
.users-table td:nth-child(5) { width: 12%; } /* Admin */

.users-table th:nth-child(6),
.users-table td:nth-child(6) { width: 14%; } /* Actions */

.users-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.75rem;
  vertical-align: middle;
  border-bottom: 1px solid rgba(98, 105, 118, 0.16);
  color: #1e293b;
}

.users-table tbody tr:hover {
  background-color: rgba(32, 107, 196, 0.03);
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  transition: 
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  min-width: 40px;
}

.btn-edit {
  composes: btn;
  color: #206bc4;
  background: rgba(32, 107, 196, 0.1);
  border-color: transparent;
  padding: 0.5rem 1rem;
  min-width: 40px;
}

.btn-edit:hover {
  color: #fff;
  background: #206bc4;
}

.btn-delete {
  composes: btn;
  color: #d63939;
  background: rgba(214, 57, 57, 0.1);
  border-color: transparent;
  padding: 0.5rem 1rem;
  min-width: 40px;
}

.btn-delete:hover {
  color: #fff;
  background: #d63939;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  border-radius: 4px;
  padding: 1.5rem;
  width: 100%;
  max-width: 30rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.modal-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0 1.5rem 0;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #1e293b;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.4285714;
  color: #1e293b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(98, 105, 118, 0.16);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out;
}

.form-control:focus {
  border-color: #206bc4;
  outline: none;
  box-shadow: 0 0 0 3px rgba(32, 107, 196, 0.1);
}
