.content {
  margin-left: 220px; /* Back to original value */
  padding: 20px 16px;
  max-width: none;
  width: calc(100% - 220px); /* Back to original value */
  box-sizing: border-box;
  overflow-x: hidden;
}

.card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: 100%;
  max-width: none; /* Remove any max-width constraint */
  box-sizing: border-box;
}

.card-body {
  padding: 40px 60px; /* Increased horizontal padding */
}

.form-group {
  margin-bottom: 24px;
}

.form-control {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.2s ease;
  box-sizing: border-box;
}

.form-control:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.btn {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-success {
  background-color: #2563eb;
  color: white;
  border: none;
}

.btn-success:hover {
  background-color: #1d4ed8;
  transform: translateY(-1px);
}

.btn-success:active {
  transform: translateY(0);
}

.btn-outline-secondary {
  background-color: white;
  color: #666;
  border: 1px solid #ddd;
}

.btn-outline-secondary:hover {
  background-color: #f5f5f5;
  border-color: #ccc;
}

/* Responsive Design */
@media (max-width: 768px) {
  .content {
    margin-left: 0;
    max-width: 100%;
    padding: 16px;
  }

  .card-body {
    padding: 24px;
  }

  .form-control {
    font-size: 14px;
    padding: 10px;
  }

  .btn {
    width: 100%;
    padding: 10px;
  }
}
