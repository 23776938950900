.login-container {
  display: flex;
  min-height: 100vh;
  background-color: white;
}

.login-left {
  flex: 1;
  background: linear-gradient(135deg, 
    #95C7EF 0%,
    #69A7D5 20%,
    #3B87C1 40%,
    #1B5F91 60%,
    #0B3F61 80%,
    #002031 100%
  );
  position: relative;
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.login-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 30% 70%, rgba(255, 255, 255, 0.08) 0%, transparent 70%);
  pointer-events: none;
}

.login-logo {
  width: 200px;
  margin-bottom: 3rem;
  position: relative;
  z-index: 1;
}

.login-tagline {
  color: white;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 2rem;
  max-width: 500px;
  position: relative;
  z-index: 1;
}

.login-illustration {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60%;
  background-image: url('../../assets/images/moralelogo.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  opacity: 0.08;
}

.login-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.login-form-container {
  width: 100%;
  max-width: 400px;
}

.welcome-text {
  font-size: 2rem;
  color: #1A1A1A;
  margin-bottom: 2rem;
  font-weight: 500;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #1A1A1A;
  font-size: 1rem;
  font-weight: 500;
}

.form-group input {
  padding: 0.75rem;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-group input:focus {
  outline: none;
  border-color: #1976D2;
}

.login-button {
  background-color: #1976D2;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 0.5rem;
}

.login-button:hover {
  background-color: #1565C0;
}

.login-button:disabled {
  background-color: #E5E5E5;
  cursor: not-allowed;
}

.google-login-button {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  background: white;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  transition: background-color 0.2s, border-color 0.2s;
  margin-bottom: 1rem;
}

.google-login-button:hover {
  background-color: #f8f9fa;
  border-color: #ddd;
}

.google-login-button svg {
  width: 18px;
  height: 18px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
  color: #666;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #E5E5E5;
}

.separator span {
  padding: 0 1rem;
  color: #666;
  font-size: 0.9rem;
}

.error-message {
  color: #DC3545;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.signup-text {
  margin-top: 2rem;
  text-align: center;
  color: #666;
}

.signup-text a {
  color: #1976D2;
  text-decoration: none;
  font-weight: 500;
}

.signup-text a:hover {
  text-decoration: underline;
}
