.page-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 4rem);
  padding: 2rem;
  margin-left: 250px; /* Match sidebar width */
  width: calc(100% - 250px); /* Ensure content doesn't overlap sidebar */
}

.page-title {
  color: #333;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.center-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: -4rem;
}

.center-content h2 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 500;
}

.center-content p {
  color: #666;
  margin-bottom: 2rem;
  font-size: 1rem;
}

.launch-button {
  background-color: #6366F1;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.launch-button:hover {
  background-color: #4F46E5;
}
