.user-guide {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.edit-controls {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.edit-button,
.save-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.edit-button {
  background-color: #f0f0f0;
  color: #333;
}

.edit-button:hover {
  background-color: #e0e0e0;
}

.user-guide-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  margin-left: 250px;
}

.user-guide-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.user-guide-header h1 {
  margin: 0;
  font-size: 2rem;
}

.header-controls {
  display: flex;
  gap: 1rem;
}

.edit-toggle,
.save-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-toggle:hover {
  background-color: #f0f0f0;
}

.save-button {
  background-color: #4CAF50;
  color: white;
}

.save-button:hover {
  background-color: #45a049;
}

.editor-menu {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
  flex-wrap: wrap;
}

.editor-menu button {
  border: none;
  background: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-menu button:hover {
  background-color: #f0f0f0;
}

.editor-menu button.is-active {
  background-color: #e0e0e0;
}

.user-guide-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  min-height: 400px;
}

.user-guide-content.editing {
  border: 1px solid #e1e1e1;
}

.editor-wrapper {
  position: relative;
  min-height: 400px;
  background: white;
  overflow: hidden;
}

/* Editor Content Styles */
.ProseMirror {
  position: relative;
  word-wrap: break-word;
  white-space: pre-wrap;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  padding: 2rem;
  height: 100%;
  min-height: 400px;
  line-height: 1.5;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.ProseMirror h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.ProseMirror p {
  margin: 0;
  min-height: 1.5em;
}

.ProseMirror a {
  color: #4A90E2;
  text-decoration: none;
}

.ProseMirror a:hover {
  text-decoration: underline;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem 0;
  pointer-events: none;
}

.ProseMirror blockquote {
  border-left: 3px solid #e1e1e1;
  padding-left: 1rem;
  color: #666;
  font-style: italic;
}

.ProseMirror code {
  background-color: #f1f1f1;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
}

.ProseMirror pre {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
}

.ProseMirror pre code {
  background: none;
  padding: 0;
  font-size: 0.9em;
}

.ProseMirror-focused {
  outline: none !important;
}

.ProseMirror * {
  box-sizing: border-box;
}

/* Table styles */
.ProseMirror table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.ProseMirror table td,
.ProseMirror table th {
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}

.ProseMirror table th {
  font-weight: bold;
  text-align: left;
  background-color: #f5f5f5;
}

.ProseMirror table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0; right: 0; top: 0; bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.ProseMirror table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

/* Loading state */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.2rem;
  color: #666;
}
