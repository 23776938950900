.sidebar {
  background-color: #0A1E3C !important;
  width: 250px;
  height: 100vh;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.sidebar-brand {
  padding: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-logo {
  max-width: 200px;
  height: auto;
  display: block;
}

.sidebar nav {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin: 8px 0;
}

.sidebar a, 
.sidebar button:not(.btn-logout) {
  display: flex;
  align-items: center;
  padding: 14px 24px;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.2s ease;
  font-size: 1rem;
}

.sidebar a:hover, 
.sidebar button:not(.btn-logout):hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
}

.sidebar a:active, 
.sidebar button:not(.btn-logout):active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
}

.sidebar a:visited, 
.sidebar button:not(.btn-logout):visited {
  color: #fff;
}

.sidebar a.active, 
.sidebar button:not(.btn-logout).active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
}

.sidebar a svg, 
.sidebar button:not(.btn-logout) svg {
  margin-right: 12px;
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.sidebar a:hover svg, 
.sidebar button:not(.btn-logout):hover svg {
  transform: scale(1.1);
}

.logout-container {
  margin-top: auto;
  padding: 0.5rem 1rem;
}

.btn-logout {
  margin: 20px;
  padding: 12px;
  background-color: rgba(231, 76, 60, 0.9);
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  width: calc(100% - 40px);
}

.btn-logout:hover {
  background-color: #e74c3c;
}

/* Responsive styles */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }
  
  .sidebar a, 
  .sidebar button:not(.btn-logout) {
    padding: 14px 20px;
    font-size: 1.1rem;
  }
}
