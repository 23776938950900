.layout {
  display: flex;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  margin-left: 250px;
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;
  width: calc(100vw - 250px);
}

.page-header {
  margin-bottom: 20px;
}

.page-title {
  font-size: 24px;
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.page-description {
  color: var(--text-secondary);
  font-size: 16px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .content {
    margin-left: 200px;
    padding: 16px;
  }
}

@media (max-width: 480px) {
  .content {
    margin-left: 0;
    padding: 16px;
  }
}
