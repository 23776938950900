body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f5f7fa;
    color: #333;
  }

  /* Content container styling */
  .content {
    margin-left: 150px; /* Reduced margin to bring the card closer to the sidebar */
    padding: 20px;
    display: flex;
    justify-content: flex-start; /* Align card closer to the sidebar */
    align-items: flex-start;
    min-height: calc(100vh - 40px);
  }

  .card {
    max-width: 98%; /* Increased width further */
    width: 98%;
    margin: 10px auto; /* Changed to auto for centering */
    border: 1px solid #e3e6ef;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 20px;
    background-color: white;
    overflow-x: auto; /* Added to prevent overflow issues */
  }

  .card-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .card p {
    font-size: 1rem;
  }

  .table-container {
    width: 100%;
    overflow-x: auto; /* Ensure the table stays responsive */
  }

  .table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    table-layout: fixed; /* Added to fix overflow issues */
  }

  .table th,
  .table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word; /* Added to ensure long words wrap and don't overflow */
  }

  .table th {
    background-color: #f8f9fa;
    font-weight: 600;
  }

  .table tr:hover {
    background-color: #f1f1f1;
  }

  .action-buttons {
    display: flex;
    gap: 10px; /* Space between buttons */
  }

  @media (max-width: 768px) {
    .content {
      margin-left: 0;
      padding: 10px;
    }

    .card {
      width: 100%;
    }

    .table th,
    .table td {
      padding: 10px;
      font-size: 0.875rem;
    }

    .btn {
      padding: 5px 8px;
      font-size: 0.875rem;
    }
  }

  .btn {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .btn:hover {
    background-color: #0056b3;
  }

/* Existing styles... */

/* Updated search bar styles */
.search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .search-bar-wrapper {
    position: relative;
    max-width: 60%;
    width: 100%;
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #495057;
  }

  .search-input {
    width: 100%;
    padding: 10px 40px; /* Add padding to accommodate the search icon */
    font-size: 1rem;
    border: 1px solid #e3e6ef;
    border-radius: 8px;
    transition: all 0.3s ease;
  }

  .search-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }

  /* Keep rest of the global styles as is... */


  /* Edit and Delete Buttons */
.action-buttons {
    display: flex;
    gap: 10px;
  }

  .btn-edit {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 10px;
  }

  .btn-delete {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 10px;
  }

  .btn-edit:hover {
    background-color: #0056b3;
  }

  .btn-delete:hover {
    background-color: #c82333;
  }

  /* Modal styles */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .form-group {
    margin-bottom: 1rem;
  }


  /* Edit Modal Styling */
.edit-modal {
    max-width: 600px; /* Increased width for better readability */
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .edit-modal h3 {
    margin-bottom: 20px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
  }

  .edit-modal form {
    width: 100%;
  }

  .edit-modal .form-group {
    margin-bottom: 15px;
  }

  .edit-modal .form-group label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .edit-modal .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: all 0.2s;
  }

  .edit-modal .form-group input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  .edit-modal .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .edit-modal .btn {
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .edit-modal .btn-primary {
    background-color: #007bff;
    color: #ffffff;
    border: none;
  }

  .edit-modal .btn-primary:hover {
    background-color: #0056b3;
  }

  .edit-modal .btn-secondary {
    background-color: #f1f1f1;
    color: #333;
    border: none;
  }

  .edit-modal .btn-secondary:hover {
    background-color: #e0e0e0;
  }

  /* Modal styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .modal-title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .form-control {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
  }

  .btn-primary:hover {
    background-color: #0056b3;
  }

  .btn-secondary {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
  }

  .btn-secondary:hover {
    background-color: #5a6268;
  }

  /* Make all titles bold */
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

/* Make input labels bold */
label {
  font-weight: bold;
}

/* Make table headers bold */
th {
  font-weight: bold;
}

/* Make property names bold */
.property-name {
  font-weight: bold;
}
