.content {
  margin-left: 175px !important;
  margin-top: 25px !important;
  width: calc(100% - 175px) !important;
  padding: 20px 40px; /* Increased horizontal padding */
  max-width: calc(100vw - 370px); /* Increased usable width */
  box-sizing: border-box;
  overflow-x: hidden;
}

.card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 25px 0 20px 0; /* Changed from auto to 0 to use full width */
  width: 100%;
  max-width: 800px;
  margin-left: 0 !important;
  margin-top: 25px !important;
  box-sizing: border-box;
}

.card .card-body {
  padding: 40px 60px; /* Increased horizontal padding */
}

.card .card-title {
  color: #333;
  font-size: 2rem !important;
  font-weight: bold !important;
  margin-bottom: 2rem;
}

.card .form-group {
  margin-bottom: 24px;
}

.card .form-group label {
  display: block;
  font-weight: bold !important;
  color: #444;
  margin-bottom: 8px;
}

.card .form-control {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.2s ease;
  box-sizing: border-box;
}

.card .form-control:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.card .btn {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.card .btn-success {
  background-color: #2563eb;
  color: white;
  border: none;
}

.card .btn-success:hover {
  background-color: #1d4ed8;
  transform: translateY(-1px);
}

.card .btn-success:active {
  transform: translateY(0);
}

.card .btn-outline-secondary {
  background-color: white;
  color: #666;
  border: 1px solid #ddd;
}

.card .btn-outline-secondary:hover {
  background-color: #f5f5f5;
  border-color: #ccc;
}

.output {
  margin-top: 32px;
  padding-top: 24px;
  border-top: 1px solid #eee;
}

.output h4 {
  color: #333;
  font-size: 18px;
  margin-bottom: 16px;
}

.output pre {
  background: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
  font-family: monospace;
  font-size: 14px;
  overflow-x: auto;
  white-space: pre-wrap;
  word-break: break-all;
  color: #333;  /* Adding explicit text color */
}

/* Responsive Design */
@media (max-width: 768px) {
  .content {
    margin-left: 0;
    max-width: 100%;
    padding: 16px;
  }

  .card .card-body {
    padding: 24px;
  }

  .card .form-control {
    font-size: 14px;
    padding: 10px;
  }

  .card .btn {
    width: 100%;
    padding: 10px;
  }
}
